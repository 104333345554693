// REPORTS

.content-section--report {
    &__section {
        margin-bottom: 2rem;
    }

    &__header {
        background-color: #CCC;
        font-weight: bold;
        padding: 0.5rem 0.2rem;
    }
    
    &__columns {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(1%, 1fr));
        //grid-template-columns: repeat(auto-fit, 1fr);
        .content-section--report__column:not(:first-child) {
            grid-column: span 2;
        }

        &--zebra {
            &:nth-child(even){
                background: rgba(#CCC, 0.4);
            }
        }

        &--checks {
            grid-template-columns: 1fr 1fr 1fr 2fr 1fr 1fr 3fr;
            .content-section--report__column:not(:first-child) {
                grid-column: span 1;
                overflow-x: hidden;
            }

            &.--error {
                &-0 {

                }
                &-100 {
                    background-color: rgba(green, 0.25);
                }
                &-200 {
                    background-color: rgba(#FF0000, 0.25);
                }
                &-300 {
                    background-color: rgba(yellow, 0.25);
                }
            }
        }

        &.--open {
            background: rgba(green, 0.2);
        }
    }

    &__column { 
        padding: 0.2rem;
        &--amount {
            text-align: right;
        }

        &--no-content {
            height: 4rem;
            padding-top: 2rem;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}