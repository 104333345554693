/* FORMS */

.wrapper--form {
    &--clean {
        border: none;
    }

    &__row {
        padding: 0.5rem 1rem 1rem;
    }

    &__help {
        text-align: right;
        padding: 0 1rem;
        &--required {
            font-size: $fontSizeReduced;
            margin-bottom: 0.25rem;
            &:before {
                content: "*";
                margin-right: 0.25rem;
                display: inline-block;
                color: red;
                font-weight: bold;
            }
        }
        &--description {
            font-size: $fontSizeReduced;
        }
    }

    &--coloured {
        background-color: $bgColorBase;
        padding: 1rem;
        margin: 0 -1rem 2rem;
    }
}

.form {
    display: block;
    max-width: 400px;
    padding: 1rem;

    &.--full-width {
        max-width: 100%;
    }
    
    &--contact {

    }

    &--sendtofriend {
        padding: 0;
    }

    &--press {
        select,
        option {
            width: calc(100% + 2px);
            margin-right: -2px;
            font-size: 0.8rem;
        }

        option:first-child {
            font-style: italic;
            color: #CCC;
        }
    }

    &--sendtofriend {
        //border: solid 1px green;
        .form-row {
            //border: solid 1px blue;
            margin-left: -0.25rem;
            margin-right: -0.025rem;

            &--preview {
                border: solid 1px #CCC;
                padding: 1rem;
                margin: 0 0.125rem 1rem;
                font-size:  $baseFontSizeReduced;
            }
        }

        .form-column {
            //border: solid 1px red;
            min-width: auto;
            flex: 1;
            margin: 0 0.25rem;
            &:first-child,
            &:last-child {
                min-width: auto;
                margin: 0 0.25rem;
            }
        }

        p {
            margin: 0 0 0.5rem;
        }
    }

    &__title {

    }

    &__subtitle {
        display: block;
        text-transform: uppercase;
        font-size: $fontSizeReduced;
        + .form__title {
            margin-top: 0;
        }
    }

    &__fieldset-title {
        font-weight: bold;
        +.form-group {
            margin-top: 0.5rem;
        }
    }

    &__fieldset-subtitle {
        display: block;
        font-size: $baseFontSizeReduced;
        
        +.form-group {
            margin-top: 0.5rem;
        }
    }

    &__feedback {
        padding: 1rem;

        @at-root .content--section__column & {
            padding: 1rem 0;
        }

        &--error {
            color: red;
        }

        &--success {
            color: green;
            font-weight: bold;
        }

        span {
            display: block;
        }
    }

    &__alternative-actions {
        padding: 1rem 0;
        font-size: 1rem;
        .link--read-more {
            font-size: 1rem;
            color: #000;
        }
    }
}

.form-section {
    margin: 1rem 0;
}

.form-row {
    margin-bottom: 0.5rem;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    @media only screen and (min-width: (64px*6)) {
        flex-direction: row;
    }

    &--input {
        label {
            min-width: 12rem;
        }

        &-captcha {
            margin-top: 2rem;
        }
    }

    &--checkbox {
        + .form-row--checkbox {
            margin-top: -1rem;
        }
    }

    &--select {
        .form-group {
            text-align: right;
            select {
                height: 1.5rem;
                border-color: #CCC;
                margin-right: -2px;
            }
        }
    }

    &--buttons {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-end;
        margin: 1rem -0.25rem 0;
        padding: 0 1rem 0.75rem 0;

        .btn {
            min-width: 8rem;
        }
    }

    &--help {
        margin-top: 1rem;
        &__text {
            font-size: $fontSizeReduced;
        }
    }

    &--feedback {
        flex-direction: column;
        margin-top: 1rem;
        font-size:  $baseFontSizeReduced;
        span {
            font-size:  $baseFontSizeReduced;
        }
    }

    &--error {
        display: block;
        margin-top: 1rem;
        color: red;
        font-size: $baseFontSizeReduced;
        padding-left: 1rem;
    }

    .feedback {
        &__title {
            
        }
        &__section {
            margin-bottom: 1rem;
            &:last-child {
                margin-bottom: 0;   
            }
        }
    }
}

.form-column {
    flex: 1;
    &:first-child {
        min-width: calc(40% - 1rem);
        margin-right: 1rem;
    }
    &:last-child {
        min-width: 60%;
    }

    &--feedback {
        display: flex;
        flex-direction: row;
        font-size:  $baseFontSizeReduced;
        justify-content: flex-end;
    }
}

.form-label {
    &.--required {
        &:after {
            content: "*";
            margin-left: 0.25rem;
            display: inline-block;
            color: red;
            font-weight: bold;
        }
    }
}

.form-field {
    &--set {
        display: flex;
    }

    &--error {
        display: block;
        color: red;
        font-size: $baseFontSizeReduced;

        &.--pw {
            margin-bottom: 1rem;
        }
    }

    &--suggestion {
        display: block;
        font-size: $baseFontSizeReduced;
        margin-bottom: 0.5rem;
    }
}

.form-fieldset {
    border: solid 1px #CCC;
    border-radius: 0.3rem;
    padding: 1rem;
    //padding: 0 1rem 0.75rem 0;

    &--address,
    &--business,
    &--password {
        background-color: #DDD;
        
        &.expanded {
            display: block;
            border: none;
            border-radius: 0 0 0.3rem 0.3rem;
        }
        &.collapsed {
            display: none;
        }
    }

    + .form-fieldset {
        margin-top: 1rem;
    }
}

.form-button {
    &--edit {
        margin-left: 1rem;
        color: red;
        cursor: pointer;
    }
}

.form-link {
    &--text {
        font-size: $baseFontSizeReduced;
        cursor: pointer;
        text-decoration: underline;
        width: 100%;
        text-align: right;
        display: block;
        margin-bottom: 0.5rem;
    }
}

// INPUTS
.form-field {
    &--text {
        padding: 0.25rem 0.5rem;
        border: solid 1px #CCC;
        width: calc(100% - 1rem);

        &::placeholder {
            font-style: italic;
            color: #BBB;
        }

        &.--int-small {
            width: 2rem;
        }

        &.--error {
            border: solid 1px red;
        }
    }

    &--textarea {
        padding: 0.25rem 0.5rem;
        border: solid 1px #CCC;
        min-height: 150px;
        min-width: calc(100% - 1rem);
        max-width: calc(100% - 1rem);

        &::placeholder {
            font-family: Arial;
            font-style: italic;
            color: #BBB;
        }
    }
}

.form-group {
    flex: 1;
    position: relative;

    &--radio {
        .form-group__option {
            display: flex;
            align-items: center;
            margin-right: 1rem;
            &:last-child {
                margin-right: 0;
            }
            label {
                order: 2;
            }
            input {
                order: 1;
            }
        }
    }

    &--checkbox {
        display: flex;
        //margin-top: 0.5rem;
        //margin-bottom: 0.5rem;
        align-items: flex-start;
        input {
            position: relative;
            margin-right: 5px;
            top: 2px;
        }
        label {
            &.--reduced {
                font-size: $fontSizeReduced;
            }

            span {
                display: inline;
            }
        }
    }

    &__secret-switch {
        position: absolute;
        width: 1.5rem;
        height: 1.3rem;
        right: 0;
        top: 0.25rem;
        font-size: $fontSizeReduced;
        cursor: pointer;
        background-image: url('../../images/icons/ic-eye-black-2.png');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 1.3rem;
        display: block;
        text-indent: -999rem;
        opacity: 0.3;
        &.--active {
            opacity: 1
        }

        @media only screen and (min-width: (64px * 8)) {
            right: -2.3rem;
        }
    }

    &.--horizontal {
        display: flex;
    }
}
