/* COUNTER */

.container--counter {
    width: 6rem;
    height: 6rem;
    border: solid 2px green;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1rem auto;

    &.--alert {
        border: solid 2px #FF0000;
    }
}

.counter {
    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 0.5rem;
    }

    &__title {
        margin-top: 0.3rem;
        font-size: 0.8rem;
        line-height: 1rem;
        text-align: center;
    }

    &__value {
        font-size: 2rem;
        line-height: 1.5rem;
    }
}