/* BUTTONS */

button {
    &:focus,
    &:active {
        outline: none;
    }
}

.button,
.btn {
    height: 2rem;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 0.75rem;
    padding: 0 0.5rem;
    cursor: pointer;
    text-decoration: none;
    position: relative;
    line-height: 1.1;

    &--primary {
        background-color: green;
        border: solid 1px green;
        color: #FFF;
        &:hover {
            background-color: #000;
        }

        &.--active {
            background-color: green;
            border: solid 1px green;
        }

        &.--disabled {
            background-color: #CCC;
            border: solid 1px #CCC;
            cursor: default;
        }

    }

    &--secundary {
        border: solid 1px #CCC;
        color: #666;
        &:hover,
        &.--selected {
            background-color: #000;
            color: #FFF;
        }

        &.--disabled {
            background-color: none;
            border: solid 1px #CCC;
            color: rgba(#000, 0.7);
            cursor: default;
            &:hover {
                background-color: transparent;
            }
        }
    }


    &--tertiary {
        border: none;
        text-decoration: underline;
        margin: 0 0.25rem;
        padding: 0;
        background-color: transparent;
    }

    &--text {
        margin-top: -1rem;
        margin-right: -0.75rem;
    }

    &--submit {
        border-radius: 0;
        height: 2rem;
    }
}

.dialog-footer__button {
    height: 2rem;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 0.75rem;
    margin: 0 0.25rem 0.25rem;
    padding: 0 0.5rem;
    min-width: 5rem;
    cursor: pointer;
    text-decoration: none;
    position: relative;
    
    &--primary {
        background-color: green;
        border: solid 1px green;
        color: #FFF;

        &.--disabled {
            background-color: #CCC;
            border: solid 1px #CCC;
            cursor: default;
        }
    }

    &--secundary {
        border: solid 1px #CCC;
        color: #666;
    }
}