/* FORMS */

.content--section__form {
    &-field {
        max-width: 20rem;
    }
    
    &-actions {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin: 1rem 0;
        align-items: center;
        font-size: 0.75rem;
        font-weight: bold;

        span {
            margin-right: 1rem;
        }
        
        .btn {
            margin-left: 0.5rem;
        }
    }
}