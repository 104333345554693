.--fulfilled {
    background-image: url('../../images/icons/ic-check-green.svg');
    background-size: 1.5rem;
    background-repeat: no-repeat;
    background-position: left center;
    padding: 0.5rem 0 0.5rem 2rem;
    color: green;
    font-weight: bold;
}

.--onaccount {
    background-image: url('../../images/icons/ic-check-green.svg');
    background-size: 1.75rem;
    background-repeat: no-repeat;
    background-position: left 0.3rem;
    padding: 0.5rem 0 0.5rem 2rem;
    color: green;
    filter: grayscale(100%);
                    opacity: 0.4;
    font-weight: bold;
}

.--unpaid {
    color: red;
    font-weight: bold;
}