/* HEADER */

.wrapper-header {
    height: 8rem;
    border-bottom: solid 1px #CCC;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.25rem;

    .branding {
        display: flex;
        flex-direction: row;
        align-items: center;

        .branding__logo--img {
            height: 100%;
            max-height: 100px;
        }
        .branding__logo--txt {
            height: 100%;
            max-height: 50px;
        }

    }

    .header {
        &__specs {

        }
    }
}