/* QUOTES */

.quote {
    &--preview {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        &__header {
            text-transform: uppercase;
            font-size: 0.8rem;
            margin-bottom: 1rem;
        }

        &__quote {
            max-width: 40rem;
        }

        &__title {
            position: relative;
            font-family: $brandfont;
            font-size: 1.2rem;
            line-height: 1.4rem;
            text-align: center;
    
            &:before,
            &:after {
                content: '"';
                font-size: 2.2rem;
                font-style: italic;
                //color: $txtColorBase-branded;
                margin: 0 0.35rem 0 0;
            }
            
            &:before {
                left: -0.25rem;
                top: 0.4rem;
                display: inline-block;
                position: relative;
                line-height: 0.75;
            }
    
            &:after {
                position: absolute;
                bottom: 0;
            }
        }
    
        &__author {
            font-style: italic;
            font-size: 1rem;
            text-align: center;
            margin-top: 1rem;
            color: $txtColorBase-branded;
        }
    }
}