/* GRAPHS*/ 

.container--graph {
    border: solid 1px red;
    border-radius: 5px;
    margin: 2rem 0;
}

.graph {
    &--bars {

    }
}