//SbC BLOCKS

.db-blocks {
    display: flex;
    flex-flow: row wrap;

    &--navigation {
        justify-content: center;
        border-bottom: solid 1px #CCC;
        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;
        }
        li {
            font-size: $fontSizeReduced;
            margin-bottom: 0.5rem;
        }
    }
}

.db-block {
    width: 8em;
    margin: 0.25rem;

    &--navigation {
        display: flex;
        flex-direction: column;
        padding: 0.25rem 0.5rem;

        h2 {
            margin: 0 0 0.75rem;
        }
    }
}

.block {
    &-edit {
        &__list {
            position: relative;
        }

        &__listitem {
            display: flex;
            flex-direction: row;
            margin: 0.25rem 0;
            &--label {
                width: 200px;
            }

            &--field {
            }

            &--delete {
                position: absolute;
                right: 0;
                top: 0.25rem;
                background-image: url("../../images/icons/cross-black.svg");
                background-repeat: no-repeat;
                width: 1.25rem;
                height: 1.25rem;
                text-indent: -999rem;
            }

        }
    }
}