/* DIALOG WINDOWS */
$defaultFadeSpeed: 0.10s;

.overlay {
    z-index: 999;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(#000, 0.4);
    display: flex;
    flex-direction: row;
    align-items: center;

    animation: fadeIn $defaultFadeSpeed ease-in;
}

body.dialog-open {
    height: 100vh;
    overflow-y: hidden;
}

/* DIALOG */

.dialog {
    background-color: #fff;
    margin: 5%;
    width: 90%;
    padding: 1rem;
    border-radius: 0.3rem;
    position: relative;
    text-align: left;
    @media only screen and (min-width: (64px * 10)) {
        width: calc(80% - 2rem);
        max-width: 640px;
        margin: auto;
    }

    &-header {
        max-width: 400px;
        h2 {
            margin-top: 0;
            margin-bottom: 1.5rem;
            line-height: 1.4;
            font-size: 1.25rem;
        }
        span {
            display: block;
            margin: 0.5rem 0 1rem;
            max-width: 400px;
            font-size: $fontSizeReduced;
        }
    }

    .content-segment__content {
        p {
            margin-bottom: 0.5rem;
        }
    }

    &-footer {
        display: flex;
        justify-content: flex-end;
        margin-top: 2rem;
    }

    &--confirmation {
        animation: fadeIn $defaultFadeSpeed ease-in;

        &.--fadeOut {
            animation: fadeOut 0.5s ease-in 15s forwards;
            animation-fill-mode: forwards;
        }

        .dialog-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            img {
                width: 125px;
            }

        }
    }

    &--content {
        width: 100%;
        border-radius: 0;
        max-width: 500px;

        animation: fadeIn $defaultFadeSpeed ease-in;

        @media only screen and (min-width: (64px * 10)) {
            width: 50%;
            border-radius: 0.3rem;
        }
    }

    &--error {
        
    }

    &--content-cookies {
        background-color: lighten($bgColor-branded-secundary, 15);
        //width: calc(90% - 2rem);
        max-width: 400px;
        font-size: $fontSizeReduced;
        position: absolute;
        z-index: 99;
        padding: 0 1rem 1rem;
        top: 15rem;
        left: calc(5% - 1rem);
        opacity: 0;

        h2 {
            margin: 0.75rem 0 1rem;
        }

        .icon--clear {
            position: absolute;
            right: 0.25rem;
            top: 0.25rem;
        }

        @media only screen and (min-width: (64px * 10)) {
            width: 50%;
            border-radius: 0.3rem;
            left: 10rem;
        }

        &.--fadeOut{
            opacity: 0;
            width: 0;
            height: 0;
            transition: width 0.5s 0.5s, height 0.5s 0.5s, opacity 1s;
       }
    
       &.--fadeIn{
            opacity: 1;
            width: 20rem;
            height: 12rem;
            transition: width 1.5s, height 1.5s, opacity 1.5s 1.5s;
       }
    }

    @keyframes fadeIn {
        from { opacity: 0; }
        to   { opacity: 1; }
    }

    @keyframes fadeOut {
        from { opacity: 1; }
        to   { opacity: 0; }
    }

    &--content-scrollable {
        overflow-y: auto;
        width: 100%;
        max-width: 700px;
        height: calc(100% - 2rem);
        border-radius: 0;

        animation: fadeIn $defaultFadeSpeed ease-in;

        @media only screen and (min-width: (64px * 10)) {
            width: 80%;
            height: 80vh;
            border-radius: 0.3rem;
        }
    }

    &--content-manual {
        max-width: 500px;
    }

    &--payment {
        iframe {
            border: none;
            width: 100%;
        }
        .dialog-content {
            margin: 0 -0.9rem 0 -1rem;
        }
    }

    &--newsletter {
        width: 100vw;
        height: 100vh;
        margin: 0;
        border: 0;
        border-radius: 0;
        padding: 0;

        @media only screen and (min-width: (64px * 10)) {
            max-width: 100vw;
        }

        @at-root body & {
            //overflow: hidden; 
            touch-action: none;
            //-webkit-overflow-scrolling: none;
            //overflow: hidden;
            /* Other browsers */
            overscroll-behavior: none;
        }
        
        .dialog-header {
            padding: 2rem 0 1rem;
            height: 5rem;
            margin: auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            span {
                margin-bottom: 0.5rem;
            }
        }

        .dialog-content {
            > div {
                width: 100vw;
                height: calc(100vh - 7rem);
                -webkit-overflow-scrolling: touch;
                overflow: auto;
            }
        }

        iframe {
            position: relative;
            border: none;
            width: 100vw;
            height: calc(100vh - 6rem);  
            
            -webkit-overflow-scrolling: touch;
            overflow: auto;
        }

        .btn--close {
            right: auto;
            left: 0;
            @media only screen and (min-width: (64px*12)) {
                left: calc(50% - 360px);
            }
            top: 6rem;
            .--link {
                order: 2;
                margin-bottom: 0;
            }
            .btn--icon {
                order: 1;
            }
        }
    }

    &--notification {
        .notification-content {
            border-top: solid 1px #CCC;
            &:first-child {
                border: none;
            }

            &__title {
                margin: 0;
                
            }

            p {
                margin-bottom: 1.5rem;
            }
        }
    }
}
