.table {
    width: 100%;

    tr {
        display: block;
        border-bottom: solid 1px #CCC;

        th,
        td {
            display: inline-block;
            width: 24%;
            text-align: left;
            padding: 0.25rem;

            .status {
                display: flex;
                flex-direction: row;
                height: 3rem;
                align-items: center;

                .loader-wrapper,
                .loader-wrapper__loader,
                svg {
                    height: 100%;
                }

                .btn { 
                    margin-left: 1rem;
                }
            }
        }
    }

    &__cell-label {
        display: block;
        font-size: $fontSizeReduced;
    }

    &__cell-value {
        display: block;
    }

    &__cell-button {
        display: inline-block;
        font-size: $fontSizeReduced;
        cursor: pointer;
        border: solid 1px #CCC;
        border-radius: 3px;
        padding: 0 0.5rem 0.2rem;
    }

    &--orders {
        tr {
            display: block;
            border-bottom: solid 1px #CCC;

            th,
            td {
                display: inline-block;
                width: 24%;
                text-align: left;
                padding: 0.25rem;
                vertical-align: text-top;
            }
        }

        &__row-header {
            background-color: rgba(255, 176, 176, 0.5);
        }

        &__cell-block:not(:first-child) {
            margin-top: 1rem;
        }

        &__cell-label {
            display: block;
            font-size: $fontSizeReduced;
            color: #AAA;

            &.--centered {
                line-height: 1.5rem; 
            }

            &.--header {
                font-weight: bold; 
                color: #000;
            }
        }
        
        &__cell-value {
            display: block;

            &--isbn {
                color: #BBB;

                &.--spaced {
                    margin-left: 23px;
                }
            }
        }

        &__cell-alert {
            display: block;
            font-size: $fontSizeReduced;
            color: red;
         }

        &__cell-button {
            display: inline-block;
            font-size: $fontSizeReduced;
            cursor: pointer;
            border: solid 1px #CCC;
            border-radius: 3px;
            padding: 0 0.5rem 0.2rem;
        }
    }

    &--account-overview {
        border-spacing: 0;
        font-size: $fontSizeReduced;
        tr {
            display: flex;
            flex-direction: row;
            border-bottom: none;

            th,
            td {
                text-align: left;
                padding: 0.25rem 0.75rem;
                vertical-align: text-top;
                &.table--account-overview__cell-summary {
                    width: 40%;
                }
            }
            td {
                &.table--account-overview__cell-summary {
                    padding: 0 0.75rem;
                }
            }
        }

        dl {

        }

        dt {
            clear: both;
            display: inline-block;
            min-width: calc(35%);
            color: #AAA;
            margin-right: 0.5rem;
        }

        dd {
            display: inline-block;
            width: calc(65% - 1rem);
            margin: 0;
        }

        &__cell-label {

            font-size: $fontSizeReduced;
        }

        &__cell-value {
        }

        &__cell-button {
            display: inline-block;
            font-size: $fontSizeReduced;
            cursor: pointer;
            border: solid 1px #CCC;
            border-radius: 3px;
            padding: 0 0.5rem 0.2rem;
        }

        &__cell-feedback {
            @at-root .table--orders-overview & {
                width: calc(100% - 1.5rem);
                padding: 1rem;
                text-align: center;
            }
        }
    }

    &--orders-overview {
        border-spacing: 0;
        tr {
            display: block;
            border-bottom: none;

            th,
            td {
                display: inline-block;
                width: calc(14% - 1.5rem);
                text-align: left;
                padding: 0.25rem 0.75rem;
                vertical-align: text-top;
                font-size: 0.8rem;
                &.table--orders-overview__cell-header {
                    font-weight: bold;
                }
                &.table--orders-overview__cell-summary {
                    width: 40%;
                }
                &.table--orders-overview__cell-errors {
                    width: calc(50%);
                    ul {
                        list-style-type: disc;
                    }
                }
            }
            td {
                &.table--orders-overview__cell-summary {
                    padding: 0 0.75rem;
                }
            }
        }

        tbody > tr.table--orders-overview__row-header {
            margin-bottom: 0;
            padding-bottom: 2rem;
            border-bottom: solid 1px #CCC;
        }

        &__cell-label {
            display: block;
            font-size: $fontSizeReduced;
        }

        &__cell-value {
            display: block;
        }

        &__cell-button {
            display: inline-block;
            font-size: $fontSizeReduced;
            cursor: pointer;
            border: solid 1px #CCC;
            border-radius: 3px;
            padding: 0 0.5rem 0.2rem;
        }

        &__cell-feedback {
            @at-root .table--orders-overview & {
                width: calc(100% - 1.5rem);
                padding: 1rem;
                text-align: center;
            }
        }

        &__form-actions {
            @at-root .table--orders-overview & {
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                margin: 1rem 0;
                align-items: center;
                width: calc(100% - 1.5rem);
                font-size: 0.75rem;
                font-weight: bold;

                span {
                    margin-right: 1rem;
                }

                .form-group {
                    flex: initial;
                }

                .btn {
                    margin-left: 0.5rem;
                }
            }
        } 
    }

    &--orders-summary {
        width: 100%;
        border-spacing: 0;
        tr {
            border-bottom: solid 1px #CCC;
            th,
            td {
                width: calc(15% - 1.5rem);
                text-align: right;
                font-weight: normal;
            }
            th:first-child,
            td:first-child {
                width: calc(40% - 0.5rem);
                padding-left: 0;
                text-align: left;
            }
            th:last-child,
            td:last-child:not(.table--orders__cell-value--totals) {
                width: calc(13.5% - 0.5rem);
                padding-right: 0;
            }
        }

        .table--orders__cell-value--totals {
            width: calc(60% - 1rem);
            padding-right: 0;

            &.--header {
                font-weight: bold; 
                color: #000;
            }
        }
    }

    &--reports {
        border-spacing: 0;
        width: 100%;

        thead {
            tr {
                border-bottom: solid 1px #CCC;
            }
        }
        
        tr {
            //border-bottom: none;
            &.table--reports__row--header {
                border-bottom: solid 1px #FFF;
                background-color: rgba(#CCC, 0.4);
            }
            @at-root &.table--reports__row--data {
                td {
                    border-bottom: solid 1px #CCC;
                }
            }
            
            th,
            td {
                width: 11%;
                padding: 0.5rem;
                text-align: left;
            }

            td {
               border-bottom: solid 2px #FFF;

               .status {
                    display: flex;
                    flex-direction: row;
                    height: 3rem;
                    align-items: center;

                    .loader-wrapper,
                    .loader-wrapper__loader,
                    svg {
                        height: 100%;
                    }

                    .btn { 
                        margin-left: 1rem;
                    }
                }
            }
        }

        &__row {
            &--header {
                border-bottom: none;
            }
            &--data {
                .content--section__actions {
                    margin-top: 0;
                } 
            }
        }

        &__cell {
            display: flex;

            &--status {
                align-items: center;
                font-size: 0.75rem;
                font-weight: bold;

                span:first-child:not(.--ok):not(.--restart) {
                    width: 3rem;
                    position: relative;
                }

                span.--restart {
                    width: auto;
                    background-color: red;
                    border: solid 1px red;
                }

                span.--ok {
                    width: 1.5rem;
                }

                .--error {
                    color: red;
                }
                .--nofiles {
                    color: red;
                }
                .--ok {
                    color: green;
                    position: relative;
                    padding-left: 1.5rem;
                    &:after{
                        position: absolute;
                        display: block;
                        top: -0.5rem;
                        left: -0.3rem;
                        content: "";
                        width: 2rem;
                        height: 2rem;
                        background-image: url('../../images/icons/ic-check-green.svg');
                        background-size: 1.25rem;
                        background-repeat: no-repeat;
                        background-position: center center;
                        
                        transition: 200ms ease-in-out all;
                    }
                }
            }
        } 
    }

    &--sales {
        tr {
            th,
            td {
                display: inline-block;
                width: 5%;

                &:first-child {
                    width: 200px;
                }
            }

            &:first-child {
                background-color: rgba(#CCC, 0.2);
                td {
                    font-weight: bold;
                    
                }
            }
            span.--inactive {
                color: rgba(#CCC, 0.6);
            }
        }

        &__counters {
            
        }

        &__counter {
            &:first-child {
                font-size: $fontSizeReduced;
                color: #AAA;
            }
            &:last-child {
                &:before {
                    content: "/";
                    display: inline-block;
                    color: #AAA;
                }
            }
        }
    }

    &--counters {
        margin: 0;

        &__row {
            // padding: 0 1rem;
            // border-bottom: solid 1px #CCC;
        }

        &__column {
            vertical-align: middle;
            &--header {
                display: flex;
                height: 100%;
            }
            &--date {
                min-width: 10rem;
            }
            &--text {
                text-align: left;
            }
        }

        &__column:not(:first-child) {
            text-align: center;
        }

        &__column--text:not(:first-child) {
            text-align: left;
        }

        &__column:nth-child(even) {
            @at-root .table--zebra & {
                background-color: rgba(#CCC, 0.2);
            }
        }
    
        &__column:nth-child(2) {
            @at-root .table--zebra-custom & {
                background-color: rgba(#CCC, 0.35);
            }
        }

        &__column:nth-child(3),
        &__column:nth-child(6) {
            @at-root .table--zebra-custom & {
                background-color: rgba(#CCC, 0.2);
            }
        }
    }
}