// WIKI

.container--card-wiki {
    width: 100%;

    .wiki-list {
        &--sections {
            display: flex;
            flex-wrap: wrap;
            padding: 1rem 0;

            li {
                flex: 1;
                width: 20%;
            }

            &__title {
                font-weight: bold;
                margin-bottom: 0.75rem;
            }
        }

        &--section {
            &-active {
                .wiki-list--sections__title {
                    color: $txtColorBase-branded;
                }
            }
        }

        &--selection {
            margin: 0;
            padding: 0;

            li {
                width: 100%;
                font-size: 0.8rem;
                margin-bottom: 0.5rem;
                line-height: 1.25;

                &.--active {
                    color: $txtColorBase-branded;
                    font-weight: bold;
                }
            }
        }
    }

    &__feature {
        display: flex;
        width: 100%;

        > div {
            width:33%;
            padding-right: 1rem;
        }

        .wiki-alert {
            color: #FF0000;
        }

        h5 {
            display: inline-block;
            text-transform: uppercase;
            font-size: 0.8rem;
            margin: 0;
            padding: 0;

            + p {
                margin-top: 0;
            }

            + h6 {
                display: inline-block;
                margin: 0 0 0 0.5rem;
            }
        }

        h6 {
            display: inline-block;
            text-transform: uppercase;
            font-size: 0.8rem;
            margin: 0;
            padding: 0;
            color: #BBB;

            + p {
                margin-top: 0;
            }

            + span {
                font-size: 0.8rem;
                display: inline-block;
                margin: 0 0 0 0.5rem;
            }
        }

        &--step {
            margin-bottom: 1rem;
        }
    }

    &__procedure {
        background-color: #EEE;
        padding: 0 0.5rem;
        margin-right: 0.5rem;
    }

}