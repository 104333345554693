.container {
    &--block {

        &__title {
            font-weight: bold;
            cursor: pointer;
        }

        &__list {
            &.--collapsed {
                display: none;
            }

            &.--expanded {
                display: block;
            }
        }

        dl {
            display: flex;
            flex-direction: column;
            margin: 0;
        }

        dt {
            font-size: $fontSizeReduced;
            font-weight: bold;
        }
        
        dd {
            margin: 0 0 1rem; 
            
            &.dd--title {
                margin: -1rem -1rem 1rem -1rem;
                padding: 1rem;
                background-color: #CCC;
            }

            &.dd--example,
            &.dd--key,
            &.dd--table {
                font-size: $fontSizeReduced;
                color: #9e1e2b;
                margin: -1rem 0 1rem;
                span:first-child {
                    margin-right: 0.25rem;
                }
            }
            &.dd--remarks {

            }
            .dd--remarks {
                &__title {
                    display: block;
                    font-size: $fontSizeReduced;
                }
                &__description {
                    display: block;
                    margin-bottom: 1rem;
                }
            }

            .dd--errors {
                &__container {
                    display: flex;
                    align-items: flex-start;
                }
                &__title {
                    width: 25%;
                    font-size: $fontSizeReduced;
                }
                &__description {
                    width: 75%;
                }
            }
        }
    }
}

