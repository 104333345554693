/** CONTENS */

.--link {
    cursor: pointer;
}

.content-header {
    &--form {
        max-width: 38rem;
        margin: auto;
    }
}

.content-text {
    margin: 1rem 0;
}

.--success {
    font-weight: bold;
    color: green;
}

.--error {
    font-weight: bold;
    color: red;
}

.content {
    &--code {
        font-size: $baseFontSizeReduced;
    }
}