/* LOADER */

.loader-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.status {
    position: relative;
    
    .loader-wrapper {
        position: relative;
        width: auto;
        height: auto;
    }
}