/* GRID */

body {
    background-color: #FFF;
    padding: 0;
    margin: 0;
}

.wrapper-navigation {
}

.wrapper--content {
    width: calc(100% - 2rem);
    margin: auto;
    padding: 1rem;

    &__columns {
        display: flex;
        flex-direction: row;

        .content-column {
            flex: 1;
            max-width: 50%;

            + .content-column {
                border-left: solid 1px #CCC;
            }

            &--full {
                max-width: 100%;
            }
        }
    }

    &__content {
        &--form {

        }
    }

    h1 {
        padding: 0 1rem;
    }

    &__header {
        .subtitle {
            display: block;
            margin: 0 1rem;
        }

        ul {
            display: flex;
            margin: -0.75rem 0.25rem 2rem;
            padding: 0;

            li {
                margin: 0 1rem;
                a {
                    text-decoration: none;
                }
            }
        }
            
    }
}

.wrapper--form {
    max-width: 40rem;
    margin: 0;

    &.--centered {
        margin: 0 auto;
    }

    + h3 {
        margin-top: 2rem;
    }
}

.content--section {
    border: solid 1px #CCC;
    border-radius: 0.3rem;
    margin: 1rem;
    padding: 1rem;
  
    &__header {
        color: #000;
        margin: -1rem -1rem 0 -1rem;;
        padding: 0.05rem 1rem;
        border-bottom: solid 3px #CCC;

        &--wiki {
            background-color: #E2CCA7;
            border-bottom: none;
        }
    }

    &__columns {
        display: flex;
        flex-direction: row;
        margin: 0 -1rem;
        
        &-header {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            margin: 0.5rem auto 1rem;
            padding-bottom: 0.5rem;
            border-bottom: solid 1px #CCC;
            div {
                text-align: center;
            }
        }
        &-subheader {
            display: block;
            text-transform: uppercase;
        }
        &-input {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin: 0.25rem 0 0.5rem;
            
            .btn {
                margin-left: 1rem;
            }
        }
    }

    &__column {
        flex: 1;
        //width: 37%;
        padding: 0 1rem;

        &--filter {
            max-width: 26%;
            background-color: #AAA;
            color: #FFF;
        }

        &--section {
            margin-bottom: 2rem;
        }

        .content--section ~ & {
            h3 {
                margin-top: 1.75rem;
            }
        }
    }

    &__actions {
        width: 100%;
        display: flex;
        flex-direction: row;
        margin-top: 2rem;
        justify-content: flex-end;
        align-items: center;
        
        &--inline {
            margin-top: 0;
        }

        .btn {
            margin-left: 0.5rem;
        }
    }

    &__table {
        display: table;
        width: 100%;
        margin: 0 1rem;
    }

    &__table-row,
    .table--counters__row,
    .table--data__row {
        display: table-row;
       
        &--header {

        }
    }

    &__table-column,
    .table--counters__column,
    .table--data__column {
        display: table-cell;
        padding: 0 1rem;
        border-top: solid 1px #CCC;
    }

    h3 {
        margin-top: 0.75rem;
    }
}

.container {
    &--card {
        display: flex;
        margin: 0 -1rem;
        align-items: flex-start;
    }
    &--block {
        flex: 1;
        display: inline-block;
        border: solid 1px #CCC;
        border-radius: 5px;
        margin: 1rem;
        padding: 1rem;
        max-width: 300px;
    }
}