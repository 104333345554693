// LISTS

ul {
    list-style-type: none;
    margin: 0;
    padding: 1rem;
}

ol {
    margin: 0;
    padding: 1rem;
}

.list {
    &--bullets {
        margin-left: 0.25rem;
        list-style-type: disc;
    }

    &--clean {
        padding: 0;
    }

    &--details {
        padding: 0;

        &__listitem {
            margin-bottom: 0.5rem;
        }

        &__listitem--label {
            display: block;
            font-size: $baseFontSizeReduced;
            font-weight: bold;
            line-height: 1;
        }

        &__listitem--field {
            
        }
    }

    &--errors {
        padding: 0;
        margin-top: 0.25rem;

        &__header {
            position: relative;
            display: inline-block;
            margin: 0.5rem 0;
            padding: 0.25rem 0.5rem;
            background-color: red;
            color: #FFF;
        }

        &__listitem {
            border-bottom: solid 1px red;

            &:last-child {
                border-bottom: none;
            }
        }

        &__listitem--label {
            display: none;
            font-size: $baseFontSizeReduced;
            font-weight: bold;
            line-height: 1;
            color: red;
        }

        &__listitem--field {
            padding: 0.25rem 0 0.5rem 0;
            display: block;
            color: red;
            
        }
    }

    &__id {
        background-color: #E2B873;
        border-radius: 5px;
        margin-right: 0.25rem;
        padding: 0.2rem 0.35rem;
        font-size: 0.75rem;
        font-weight: bold;
        color: #FFF;
        display: inline-block;
        align-items: center;

        &--cat {
            background-color: #9e1e2b;

            > span {
                display: flex;
                
            }
            .list__id--delete {
                color: #000;
            }
        }

        &--subcat {
            > span {
                display: flex;
            }
            .list__id--delete {
                color: #000;
            }
        }

        &--delete {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 1rem;
            height: 1rem;
            margin-left: 0.25rem;
            border-radius: 50%;
            background-color: #FFF;
        }

        &--nur {
            background-color: #CCC;
            color: #000;
            margin-right: 0;
            margin-left: 0.25rem;

            > span {
                display: flex;
            }
        }
    }

    &--nurs {
        span {
            margin-right: 1rem;
        }
    }

    &--ordered {
        li {
            margin-bottom: 1rem;
        }
    }

    &--preview {
        padding: 0.1rem 0;
        li {
            padding: 0.1rem 0;
        }

        .list--preview {
            padding-left: 2rem;
        }
    }

    &--vert {
        padding: 0;

        li {
            display: flex;
            flex-direction: column;
            position: relative;
        }
    }

    
}