/* VARIABLES */

// directory settings
$fontDir: "../../fonts";
$imagesDir: "../../../images";

// grid settings
$contentWidth: 40rem;
$triggerGridChange: calc(14*64px + 1px);
$triggerMobileNav: calc(14*64px + 1px);

// z-indices;
$zHeader: 100;
$zContent: 90;
$zFooter: 80;

// nav bar en search bar
$barHeight: 1.7rem;
$barHeightContent: calc(#{$barHeight} - 0.7rem);
$barPadding: 0.1rem;

// blocks settings
$initialLines: 2;

// text colors
$txtColorBase: #000;
$txtColorBase-inverted: #FFF;
$txtColorBase-branded: #9e1e2b;

// background colors
//$bgColorBase: #faf2f2;
//$bgColorBase-dark: #f9dfe1;
$bgColorBase: #f5f4f2;
$bgColorBase-light: #FFF;
$bgColorBase-dark: #d7d2cb;

$bgColor-branded-dark: #96281B;
$bgColor-branded-secundary: #E2B873;

$bgColor-btn-primary: green;

$bgColor-myparcel: #0F5C47;

// border colors
$borderColorBase: #CCC;
$borderColor-branded: #96281B;